import { orderByValue } from "./sortGrid";

export const columns = {
    cadastros: {
        clientes: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'CODIGOCLIENTE',
            },
            {
                text: 'Razão Social',
                sortable: true,
                value: 'NOMEFANTASIACLIENTE',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        carros: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'CODIGOCARRO',
            },
            {
                text: 'Placa Carro',
                sortable: true,
                value: 'PLACACARRO',
            },
            {
                text: 'Carro',
                sortable: true,
                value: 'DESCRICAOCARRO',
            },
            {
                text: 'Num. Carro',
                sortable: true,
                value: 'NUMEROCARRO',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        atividades: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'CODTAREFAEXTERNO',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'DESCTAREFAEXTERNO',
            },
            {
                text: 'Envolve Região',
                sortable: true,
                value: 'REGIAO',
            },
            {
                text: 'Envolve Carro',
                sortable: true,
                value: 'CARRO',
            },
            {
                text: 'Envolve Cliente',
                sortable: true,
                value: 'CLIENTE',
            },
            {
                text: 'Mostrar na TV',
                sortable: true,
                value: 'MOSTRARTV'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        regioes: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'ID',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'DESCRICAO',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        departamentos: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'ID',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'DESCRICAO',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        usuarios: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'ID',
            },
            {
                text: 'Nome',
                sortable: true,
                value: 'NOMECOMPLETOUSUARIO',
            },
            {
                text: 'E-mail',
                sortable: true,
                value: 'EMAILUSER',
            },
            {
                text: 'Departamento',
                sortable: true,
                value: 'DESCRICAOSETOR',
            },
            {
                text: 'Supervisor',
                sortable: false,
                value: 'SUPERVISOR'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ]
    },
    lancamentos: {
        agenda: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'ID',
            },
            {
                text: 'Responsável',
                sortable: true,
                value: 'NOMECOMPLETOUSUARIO',
            },
            {
                text: 'Cliente',
                sortable: true,
                value: 'NOMEFANTASIACLIENTE',
            },
            {
                text: 'Deslocamento',
                sortable: true,
                value: 'DESLOC',
            },
            {
                text: 'Atividade',
                sortable: true,
                value: 'DESCTAREFAEXTERNO',
            },
            {
                text: 'Data Inicial',
                sortable: true,
                value: 'DATAINI',
            },
            {
                text: 'Hora Inicial',
                sortable: true,
                value: 'HORAINI',
            },
            {
                text: 'Data Final',
                sortable: true,
                value: 'DATAFIM',
            },
            {
                text: 'Hora Final',
                sortable: true,
                value: 'HORAFIM',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'CODIGO_STATUS',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        trackProjetos: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            {
                text: 'Tipo',
                sortable: true,
                value: 'tipoProjeto',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'descricao',
            },
            {
                text: 'Status',
                sortable: true,
                value: 'statusTarefa',
            },
            {
                text: 'Prioridade',
                sortable: true,
                value: 'prioridade',
            },
            {
                text: 'Progresso %',
                sortable: true,
                value: 'progresso',
            },
            {
                text: 'Tarefa',
                sortable: true,
                value: 'idTarefaRedmine',
            },
            {
                text: 'Observação',
                sortable: true,
                value: 'observacao',
            },
            {
                text: 'Data Inicial',
                sortable: true,
                value: 'dataIni',
            },
            {
                text: 'Data Final',
                sortable: true,
                value: 'dataFim',
            },
            {
                text: 'Clientes',
                sortable: true,
                value: 'clientesSolicitantes',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                width: '100px'
            }
        ],
        etapasProjeto: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            {
                text: 'Descrição',
                align: 'start',
                sortable: true,
                value: 'descricao',
            },
            {
                text: 'Responsável',
                align: 'start',
                sortable: true,
                value: 'responsavel',
            },
            {
                text: 'Progresso',
                align: 'start',
                sortable: true,
                value: 'progresso',
            },
            {
                text: 'Observação',
                align: 'start',
                sortable: true,
                value: 'obs',
            },
            {
                text: 'Data Inicial',
                align: 'start',
                sortable: true,
                value: 'dataInicial',
            },
            {
                text: 'Data Final',
                align: 'start',
                sortable: true,
                value: 'dataFinal',
            },
            {
                text: 'Ações',
                sortable: false,
                value: 'actions',
                width: '40px'
            }
        ],
        clientesSolicitantesProjeto: [
            {
                text: 'ID',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            {
                text: 'Nome Fantasia',
                align: 'start',
                sortable: true,
                value: 'nomeFantasia',
            },
            {
                text: 'Observação',
                align: 'start',
                sortable: true,
                value: 'observacao',
            },
            {
                text: 'Ações',
                sortable: false,
                value: 'actions',
                width: '40px'
            }
        ],
        track: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID",
                width: '2%'
            },
            {
                text: "Descrição",
                sortable: true,
                value: "DESCRICAO",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Atividade",
                sortable: true,
                value: "ACTIVITY",
            },
            {
                text: "Módulo",
                sortable: true,
                value: "MODULE",
            },
            {
                text: "Categoria",
                sortable: true,
                value: "CATEGORY",
            },
            {
                text: "Tag",
                sortable: true,
                value: "TAG",
            },
            {
                text: "Início",
                sortable: true,
                value: "HORAINICIO",
            },
            {
                text: "Fim",
                sortable: true,
                value: "HORAFIM",
            },
            {
                text: "Total",
                sortable: true,
                value: "TOTAL",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                width: '150px'
            }
        ],
        nfseExpress: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID_REF_PARCELA_CONTA",
                width: '2%'
            },
            {
                text: "ID Conta",
                sortable: true,
                value: "ID_REF_CONTA",
                cellClass: "descriptionColumn text-truncate",
                align: "start"
            },
            {
                text: "Cliente",
                sortable: true,
                value: "CLIENTE",
                align: "left",
            },
            {
                text: "N. RPS",
                sortable: true,
                value: "NUMERORPS",
                align: "right",
            },
            {
                text: "N. NFS-e",
                sortable: true,
                value: "NUMERONFSE",
                align: "right",
            },
            {
                text: "E-mail",
                sortable: true,
                value: "emailState",
                align: "start",
                width: '150px'
            },
            {
                text: "Serviço",
                sortable: true,
                value: "DISCRIMINACAO",
                align: "start",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Valor Líquido",
                sortable: true,
                align: "left",
                value: "VALOR_LIQUIDO",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false
            }
        ],
        nfseChecks: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID_REF_PARCELA_CONTA",
                width: '2%'
            },
            {
                text: "ID Empresa",
                sortable: true,
                value: "ID_REF_EMPRESA",
                width: '1%',
                align: "start"
            },
            {
                text: "Prestador",
                sortable: true,
                value: "RAZAO_PRESTADOR",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Tomador",
                sortable: true,
                value: "TOMADOR",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "ID Contas Receber",
                sortable: true,
                value: "ID_REF_CONTA",
                width: '1%'
            },
            {
                text: "Serviço",
                sortable: true,
                value: "DISCRIMINACAO",
                align: "start",
                cellClass: "descriptionColumn text-truncate"
            },
            {
                text: "Valor Bruto",
                sortable: true,
                align: "start",
                value: "VALOR_BRUTO",
            },
            {
                text: "Taxa ISSQN",
                sortable: true,
                align: "end",
                value: "TAXA_ISSQN",
            },
            {
                text: "Valor ISSQN",
                sortable: true,
                align: "start",
                value: "VALOR_ISSQN",
            },
            {
                text: "Valor Líquido",
                sortable: true,
                align: "start",
                value: "VALOR_LIQUIDO",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false
            }
        ],
        cargaDados: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "ID",
                width: '2%'
            },
            {
                text: "Consulta",
                sortable: true,
                value: "consulta"
            },
            {
                text: "Período",
                sortable: true,
                value: "periodo"
            },
            {
                text: "Data Início",
                sortable: true,
                value: "dataInicio",
            },
            {
                text: "Hora Início",
                sortable: true,
                value: "horaInicio",
            },
            {
                text: "Data Fim",
                sortable: true,
                value: "dataFim",
            },
            {
                text: "Hora Fim",
                sortable: true,
                value: "horaFim",
            },
            {
                text: "Tempo decorrido",
                sortable: true,
                value: "tempoExecucao",
            },
            {
                text: "Solicitante",
                sortable: true,
                value: "solicitante",
            },
            {
                text: 'Execução',
                sortable: false,
                value: "LOADING",
                align: 'center'
            },
            {
                text: 'Retornou Dados',
                sortable: false,
                value: "NO_DATA",
                align: 'center'
            }
        ],
        controleFinanceiro: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "id",
                width: '2%'
            },
            {
                text: "Responsável",
                sortable: true,
                value: "nomeUsuario"
            },
            {
                text: "Saldo em Dinheiro",
                sortable: true,
                align: "start",
                value: "saldoDinheiro",
                width: '10%'
            },
            {
                text: "Saldo em Cartão",
                sortable: true,
                align: "start",
                value: "saldoCartao",
                width: '10%'
            },
            {
                text: "Status",
                sortable: true,
                align: "start",
                value: "idStatus",
                width: '5%'
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                width: '100px'
            }
        ],
        controleServicos: [
            {
                text: "ID",
                align: "start",
                sortable: true,
                value: "id",
                width: '2%'
            },
            {
                text: "Responsável",
                sortable: true,
                value: "colaborador"
            },
            {
                text: "Cliente",
                sortable: true,
                value: "cliente"
            },
            {
                text: "Horas",
                sortable: true,
                align: "start",
                value: "qtdHoras",
                width: '10%'
            },
            {
                text: "Saldo",
                sortable: true,
                align: "start",
                value: "saldoHoras",
                width: '10%'
            },
            {
                text: 'Status',
                sortable: false,
                value: 'status',
                width: '15%'
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                width: '100px'
            }
        ]
    },
    dashboards: {
        saldoBancos: [
            {
                text: 'Código ERP',
                align: 'start',
                sortable: true,
                value: 'codigoCC',
            },
            {
                text: 'Titular',
                sortable: true,
                value: 'titular',
            },
            {
                text: 'Agência / Conta',
                sortable: true,
                value: 'agenciaConta',
            },
            {
                text: 'Saldo Conta',
                sortable: true,
                value: 'saldoCC',
                sort: (a, b) => orderByValue(a, b)
            },
            {
                text: 'Saldo Aplicação',
                sortable: true,
                value: 'saldoAplicacao',
                sort: (a, b) => orderByValue(a, b)
            },
            {
                text: 'Total',
                sortable: true,
                value: 'saldoTotal',
                sort: (a, b) => orderByValue(a, b)
            }
        ],
        saldoCaixas: [
            {
                text: 'Código ERP',
                align: 'start',
                sortable: true,
                value: 'codigoCxa',
            },
            {
                text: 'Descrição',
                sortable: true,
                value: 'descricao',
            },
            {
                text: 'Saldo Caixa',
                sortable: true,
                value: 'saldoCxa',
                sort: (a, b) => orderByValue(a, b)
            }
        ]
    }
}