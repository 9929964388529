<template>
    <v-container fluid ref="myContainer" style="padding: 0px 12px 0px 12px;">
        <v-row align="center">
            <v-col cols="auto">
                <v-btn fab text small color="darken-2" @click="openFilter()">
                    <v-icon small>
                        mdi-filter
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col md="3" :cols="$vuetify.breakpoint.mobile ? 9 : null">
                <v-select label="Visualização" v-model="dateInfo.typeofView" :items="dateInfo.typeofViewData" />
            </v-col>
            <v-col md="6" :cols="$vuetify.breakpoint.mobile ? 12 : null" class="pt-0"
                :style="$vuetify.breakpoint.mobile ? 'margin-top: -25px;' : ''">
                <v-row :justify="$vuetify.breakpoint.mobile ? 'space-between' : 'start'">
                    <v-col :cols="$vuetify.breakpoint.mobile ? 4 : 'auto'">
                        <v-btn outlined color="darken-2"
                            :disabled="dateInfo.actualMonth?.getMonth() === dateInfo.currentMonthExt?.getMonth()"
                            @click="setCurrentDate()" v-if="dateInfo.typeofView === 'Mensal'">
                            Mês atual
                        </v-btn>
                        <v-btn outlined color="darken-2" :disabled="dateInfo.actualYear === new Date().getFullYear()"
                            @click="setCurrentDate()" v-else>
                            Ano atual
                        </v-btn>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mobile ? 8 : 'auto'">
                        <v-btn fab text small color="darken-2" @click="updateDate(false)">
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn fab text small color="darken-2" @click="updateDate(true)">
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <span>
                            {{ dateInfo.title }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto" :cols="$vuetify.breakpoint.mobile ? 9 : null">
                <v-select label="Conta Totalizadora" v-model="comparativoMensal.selectedOption"
                    :items="availableAccounts.rows" item-text="item" item-value="item" clearable v-if="dateInfo.typeofView === 'Mensal'"/>
            </v-col>
        </v-row>
        <v-layout justify-center align-center>
            <DxPivotGrid :allow-sorting-by-summary="true" :allow-sorting="true" :allow-filtering="true"
                :show-row-grand-totals="false" :show-column-grand-totals="dateInfo.typeofView === 'Anual'"
                :allow-expand-all="true" :load-panel.enabled="false"
                :height="this.$vuetify.breakpoint.mobile ? '100%' : this.$vuetify.breakpoint.height - 200"
                :show-borders="true" :data-source="dataSource" :focused-row-enabled="true" row-header-layout="tree"
                @exporting="onExporting" @cell-prepared="(e) => updateIcon(e)" @cell-click="(e) => onCellClick(e)"
                @initialized="(e) => initializedPivotGrid(e)">
                <DxLoadPanel :enabled="false" />
                <DxFieldChooser :enabled="false" :allow-search="true" />
                <DxExport :enabled="true" />
                <DxFieldPanel :visible="false" :show-filter-fields="false" />
                <DxHeaderFilter :allow-search="true" :width="this.$vuetify.breakpoint.mobile ? 200 : 500" />
            </DxPivotGrid>
        </v-layout>
        <ModalCrud :opened="modals.filter.open" title="Filtrar" v-model="modals.filter.open"
            :loading="$store.state.loading" v-if="modals.filter.open">
            <template v-slot:body>
                <v-form ref="form" v-model="modals.filter.data.isValid" lazy-validation>
                    <v-row cols="12" no-gutters>
                        <v-col md="12">
                            <v-autocomplete class="mt-5" outlined auto-select-first v-model="modals.filter.data.idDre"
                                :items="modals.filter.dataset.dres" tabindex="1" item-value="id" item-text="descricao"
                                clearable label="DRE" />
                        </v-col>
                    </v-row>
                    <v-row cols="12" no-gutters>
                        <v-col md="12">
                            <v-autocomplete outlined auto-select-first multiple v-model="modals.filter.data.idEmpresas"
                                @keydown.native.shift="$event => selectOnShift($event, modals.filter.data.idEmpresas, modals.filter.dataset.empresas, 'id')"
                                :items="modals.filter.dataset.empresas" tabindex="2" item-value="id"
                                item-text="descricao" clearable label="Empresas" />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn depressed color="success" @click="loadData(false)" class="button-width-large">
                        <v-icon left>mdi-content-save-plus</v-icon>
                        Confirmar
                    </v-btn>
                    <v-btn depressed color="error" @click="closeFilter(false)" class="button-width-large">
                        <v-icon left>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </template>
        </ModalCrud>
        <ModalCrud :opened="modals.saveFilters.open" title="Salvar Filtro" v-model="modals.saveFilters.open"
            :loading="$store.state.loading" v-if="modals.saveFilters.open">
            <template v-slot:body>
                <div class="my-5">
                    <h3>Deseja salvar os filtros para a próxima execução?</h3>
                </div>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn depressed color="success" @click="saveFilter()" class="button-width-large">
                        <v-icon left>mdi-content-save-plus</v-icon>
                        Confirmar
                    </v-btn>
                    <v-btn depressed color="error" @click="() => modals.saveFilters.open = !modals.saveFilters.open"
                        class="button-width-large">
                        <v-icon left>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </template>
        </ModalCrud>
    </v-container>
</template>

<script>
import Vue, { defineComponent } from 'vue';
import { DxPivotGrid } from 'devextreme-vue';
import DxThemes from 'devextreme/ui/themes';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ptMessages from "../../../../assets/translation/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { DxExport, DxFieldChooser, DxFieldPanel, DxHeaderFilter, DxLoadPanel } from 'devextreme-vue/pivot-grid';
import { dateFunctions } from '@/utils/dateFunctions'
import ModalCrud from '@/components/ModalCrud.vue';
import selectOnShift from '@/utils/selectOnShift';
import api from '@/api/api';
export default defineComponent({
    name: 'TabDRE',
    data() {
        return {
            fields: [
                {
                    caption: 'Conta',
                    width: this.$vuetify.breakpoint.mobile ? 150 : 400,
                    dataField: 'descricaoConta',
                    area: 'row',
                    allowSorting: false,
                    sortingMethod: (a, b, children) => {
                        return null
                    }
                },
                {
                    caption: 'Centro de Custo',
                    dataField: 'custoAnalitico',
                    width: 150,
                    area: 'row'
                },
                {
                    caption: 'Lançamento',
                    dataField: 'descricaoLancamento',
                    width: 150,
                    area: 'row'
                },
                {
                    caption: 'Data',
                    dataField: 'dataLancamento',
                    dataType: 'date',
                    area: 'column',
                    groupInterval: 'month',
                    customizeText: function (cellInfo) {
                        return cellInfo.valueText.charAt(0).toUpperCase().concat(cellInfo.valueText.slice(1))
                    }
                },
                {
                    caption: 'Valor',
                    dataField: 'valor',                    
                    dataType: 'number',
                    summaryType: 'sum',
                    format: {
                        currency: 'BRL',
                        precision: 2,
                        type: 'currency'
                    },
                    area: 'data',
                },
                {
                    caption: 'Comparativo',
                    dataField: 'valor',
                    dataType: 'number',
                    summaryType: 'sum',
                    area: 'data',
                    format: {
                        precision: 2,
                        type: 'percent'
                    },
                    summaryDisplayMode: 'percentVariation',
                }
            ],
            dataset: [],
            dataSource: [],
            pivotGridInstance: null,
            containerHeight: 0,
            modals: {
                filter: {
                    open: false,
                    dataset: {
                        dres: [],
                        empresas: []
                    },
                    data: {
                        isValid: false,
                        idDre: null,
                        idEmpresas: []
                    }
                },
                saveFilters: {
                    open: false
                }
            },
            pivotGridSelection: null,
            dateInfo: {
                title: '',
                currentMonthExt: null,
                actualMonth: null,
                actualYear: null,
                typeofView: 'Mensal',
                typeofViewData: ['Anual', 'Mensal']
            },
            comparativoMensal: {
                selectedOption: null
            }
        }
    },
    components: {
        DxPivotGrid,
        DxExport,
        DxFieldChooser,
        DxHeaderFilter,
        DxFieldPanel,
        ModalCrud
    },
    methods: {
        renderIcon(iconType, id) {
            switch (iconType) {
                case '+':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-plus',
                        style: {
                            color: '#0cab14',
                            fontSize: '18px'
                        }
                    });
                case 'plus-percent':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-arrow-up-thin',
                        style: {
                            color: '#0cab14',
                            fontSize: '15px'
                        }
                    });
                case 'minus-percent':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-arrow-down-thin',
                        style: {
                            color: '#F00000',
                            fontSize: '15px'
                        }
                    });
                case '-':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-minus',
                        style: {
                            color: '#F00000',
                            fontSize: '18px'
                        },
                    });
                case '=':
                    return this.$createElement('v-icon', {
                        class: 'mdi mdi-equal',
                        style: {
                            color: '#2196f3',
                            fontSize: '18px'
                        }
                    });;
                default:
                    return;
            }
        },
        onCellClick: function (e) {
            if (e.cell?.text?.includes('=')) {
                e.cancel = true;
            };
            if (this.$data.pivotGridSelection === null) {
                this.$data.pivotGridSelection = e.cellElement;
                this.$data.pivotGridSelection.style.backgroundColor = '#1876D1';
                this.$data.pivotGridSelection.style.color = '#FFFF';
            } else {
                this.$data.pivotGridSelection.style.backgroundColor = null;
                this.$data.pivotGridSelection.style.color = null;
                this.$data.pivotGridSelection = e.cellElement;
                this.$data.pivotGridSelection.style.backgroundColor = '#1876D1';
                this.$data.pivotGridSelection.style.color = '#FFFF';
            };
        },
        updateIcon(e) {
            if (e.cell?.text?.includes('%') && this.dateInfo.typeofView === 'Anual') {                
                e.cellElement.classList.add('dre-comparer');
                let iconComponent = null;

                if (!e.cell?.text?.includes('-')) {
                    iconComponent = this.renderIcon('plus-percent')
                };
                if (e.cell?.text?.includes('-')) {
                    iconComponent = this.renderIcon('minus-percent')
                };

                if (iconComponent) {
                    const vm = new Vue({
                        render: h => iconComponent
                    }).$mount();
                    e.cellElement.prepend(e.cellElement.firstChild, vm.$el);
                    const textNode = e.cellElement.querySelectorAll('span')[1];
                    if (textNode) {
                        textNode.textContent = e.cell.text.substring(1).trim();
                    }
                }
            }
            if (e.cell.columnType === 'D' || e.cell.columnType === 'GT') {
                if (Array.isArray(e.cell.rowPath) && e.cell.rowPath[0].includes('=')) {
                    e.cellElement.classList.add('dre-totalizer');
                };
            }
            if (e.area === 'row') {
                if (e.cell.type === 'D' & e.cell?.path?.length === 1) {

                    let iconComponent = null;
                    if (e.cell?.text?.includes('-')) {
                        iconComponent = this.renderIcon('-')
                    }
                    if (e.cell?.text?.includes('+')) {
                        iconComponent = this.renderIcon('+')
                    }
                    if (e.cell?.text?.includes('=')) {
                        e.cellElement.classList.add('dre-totalizer');
                        iconComponent = this.renderIcon('=')
                    }
                    if (iconComponent) {
                        const vm = new Vue({
                            render: h => iconComponent
                        }).$mount();
                        e.cellElement.prepend(e.cellElement.firstChild, vm.$el);
                        const textNode = e.cellElement.querySelectorAll('span')[1];
                        if (textNode) {
                            textNode.textContent = e.cell.text.substring(1).trim();
                        }
                    }
                };
                if (e.cell.type === 'T' & e.cell?.path?.length === 1) {
                    let iconComponent = null;
                    if (e.cell?.text?.includes('-')) {
                        iconComponent = this.renderIcon('-')
                    }
                    if (e.cell?.text?.includes('+')) {
                        iconComponent = this.renderIcon('+')
                    }
                    if (e.cell?.text?.includes('=')) {
                        iconComponent = this.renderIcon('=')
                    }
                    if (iconComponent) {
                        const vm = new Vue({
                            render: h => iconComponent
                        }).$mount();
                        e.cellElement.prepend(e.cellElement.firstChild, vm.$el);
                        const textNode = e.cellElement.querySelectorAll('span')[1];
                        if (textNode) {
                            textNode.textContent = e.cell.text.substring(1).trim();
                        }
                    }
                }
            }
            if (e.area === "row" && e.cell && e.cell?.text?.includes('=')) {
                const expandIconContainer = e.cellElement.querySelector(".dx-expand-icon-container");
                if (expandIconContainer) {
                    expandIconContainer.innerHTML = '';
                };
            };
        },
        async onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('DRE');
            exportPivotGrid({
                component: e.component,
                worksheet: worksheet,
                loadPanel: {
                    enabled: false
                },
                customizeCell: function (options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                }
            }).then(function () {
                worksheet.autoFilter = {
                    from: {
                        row: 1,
                        column: 1
                    },
                    to: {
                        row: worksheet.lastRow?.number,
                        column: worksheet.lastColumn?.number
                    }
                }
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Demonstrativo de Resultado do Exercício - CSNOTE.xlsx');
                    });
            });
            e.cancel = true;
        },
        initializedPivotGrid(e) {
            this.$data.pivotGridInstance = e.component;
            setTimeout(() => {
                e.component.repaint()
            }, 500)
        },
        async openFilter() {
            try {
                this.$store.state.loading = true;
                const { data } = await api.get('/dash/dre');
                this.$data.modals.filter.dataset = {
                    ...this.$data.modals.filter.dataset,
                    ...data
                };
                this.$data.modals.filter.open = true;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        closeFilter(closeWithLoad) {
            this.$data.modals.filter.dataset = {
                ...this.$data.modals.filter.dataset,
                empresas: [],
                dres: []
            };
            if (closeWithLoad) {
                this.$data.modals.filter.open = false;
                this.$data.modals.saveFilters.open = true;
                return;
            };
            this.$data.modals.filter.open = false;
        },
        async saveFilter() {
            try {
                this.$store.state.loading = true;
                await api.put('/dash/dre', this.$data.modals.filter.data);
                this.$data.modals.saveFilters.open = false;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        async loadData(firstLoad) {
            try {
                this.$store.state.loading = true;

                if (firstLoad) {
                    const { data } = await api.post('/dash/dre', {
                        ...this.$data.modals.filter.data,
                        firstLoad: true,
                        typeofView: this.$data.dateInfo.typeofView,
                        period: this.convertPeriodToNumber()
                    });

                    if (!data.haveFilter) {
                        await this.openFilter();
                        this.$toast.warning('Informe os parâmetros de apuração.')
                    } else {
                        this.dataSource = new PivotGridDataSource({
                            fields: this.$data.fields,
                            store: data.dataset
                        });
                        this.$data.modals.filter.data = {
                            ...data.filters
                        }
                    };
                } else {
                    const { data } = await api.post('/dash/dre', {
                        ...this.$data.modals.filter.data,
                        firstLoad: false,
                        typeofView: this.$data.dateInfo.typeofView,
                        period: this.convertPeriodToNumber()
                    });
                    this.dataSource = new PivotGridDataSource({
                        fields: this.$data.fields,
                        store: data.dataset
                    });
                    this.$data.modals.filter.data = {
                        ...data.filters
                    }
                    this.closeFilter(true);
                };
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        setCurrentDate() {
            this.$data.dateInfo.currentMonthExt = this.$data.dateInfo.actualMonth;
            this.$data.dateInfo.actualYear = new Date().getFullYear();
            this.mountDate();
        },
        async updateDate(next) {
            let actualMonth = this.$data.dateInfo.currentMonthExt;
            if (this.$data.dateInfo.typeofView === 'Mensal') {
                if (next) {
                    if (actualMonth.getMonth() === 11) {
                        this.$data.dateInfo.currentMonthExt.setMonth(0);
                        this.$data.dateInfo.currentMonthExt.setFullYear(this.$data.dateInfo.currentMonthExt.getFullYear() + 1);
                    } else {
                        this.$data.dateInfo.currentMonthExt.setMonth(this.$data.dateInfo.currentMonthExt.getMonth() + 1);
                    }
                } else {
                    if (actualMonth.getMonth() === 0) {
                        this.$data.dateInfo.currentMonthExt.setMonth(11);
                        this.$data.dateInfo.currentMonthExt.setFullYear(this.$data.dateInfo.currentMonthExt.getFullYear() - 1);
                    } else {
                        this.$data.dateInfo.currentMonthExt.setMonth(this.$data.dateInfo.currentMonthExt.getMonth() - 1);
                    }
                }
            } else {
                if (next) {
                    this.$data.dateInfo.actualYear = this.$data.dateInfo.actualYear + 1
                } else {
                    this.$data.dateInfo.actualYear = this.$data.dateInfo.actualYear - 1
                }
            }
            this.mountDate(false);
        },
        mountDate(inicialize) {
            try {
                this.$data.dateInfo.actualMonth = new Date();
                if (inicialize) {
                    this.$data.dateInfo.actualYear = new Date().getFullYear();
                    this.$data.dateInfo.currentMonthExt = new Date();
                };
                if (this.$data.dateInfo.typeofView === 'Mensal') {
                    this.$data.dateInfo.title = this.dateFunctions.formatYearMonth(this.$data.dateInfo.currentMonthExt.getMonth(), this.$data.dateInfo.currentMonthExt.getFullYear())
                } else {
                    this.$data.dateInfo.title = this.$data.dateInfo.actualYear;
                }
            } catch (error) {

                this.$toast.error('Falha ao carregar as datas!')
            }
        },
        convertPeriodToNumber() {
            if (this.$data.dateInfo.typeofView === 'Mensal') {
                return this.$data.dateInfo.currentMonthExt.getFullYear() + (this.$data.dateInfo.currentMonthExt.getMonth() + 1).toString().padStart(2, '0');
            } else {
                return this.$data.dateInfo.actualYear;
            }
        }
    },
    watch: {
        '$vuetify.theme.dark': function (newValue) {
            if (newValue) {
                DxThemes.current('isDark');
            } else {
                DxThemes.current('isLight');
            }
        },
        'dateInfo.typeofView': async function () {
            this.mountDate();
            await this.loadData(true);
        },
        'dateInfo.title': async function () {
            await this.loadData(true);
        },
        'comparativoMensal.selectedOption': function (newValue, oldValue) {
            if (!this.dateInfo.typeofView === 'Mensal') {
                return;
            }
            if (newValue === null) {
                if (this.dataSource instanceof PivotGridDataSource) {
                    this.dataSource.fields([
                        ...this.fields
                    ]);
                    this.dataSource.load();
                }
            };
            if (newValue) {
                const findSelectedTotal = this.availableAccounts.availableAccounts.find(x => x.item === newValue);
                this.dataSource.fields([
                    ...this.fields,
                    {                        
                        dataField: 'valor',
                        caption: `Comparativo`,
                        dataType: 'number',                        
                        summaryType: 'custom',
                        area: 'data',
                        format: {
                            precision: 2,
                            type: 'percent'
                        },                        
                        calculateCustomSummary: function (options) {
                            if (options.summaryProcess === 'start') {
                                options.totalValue = 0;
                            }
                            if (options.summaryProcess === 'calculate') {
                                options.totalValue += options.value;
                            }
                            if (options.summaryProcess === 'finalize') {
                                
                                options.totalValue = options.totalValue / findSelectedTotal.value;
                            }
                        }.bind(this)
                    }
                ]);
                this.dataSource.load();
            }
        }
    },
    computed: {
        availableAccounts() {            
            if (this.dataSource instanceof PivotGridDataSource) {
                if (!this.dateInfo.typeofView === 'Mensal') return;
                const rows = this.dataSource.getData().rows.map(x => x.text.replaceAll('- ', '').replaceAll('+ ', '').replaceAll('= ', ''));
                const values = this.dataSource.getData().values.map(x => x[0][0]).filter((value, index) => index !== 0)
                const availableAccounts = rows.map((item, index) => ({ item, value: values[index] }));
                return {
                    rows,
                    availableAccounts
                }
            } else {
                return []
            }
        }
    },
    async mounted() {
        this.mountDate(true);
        await this.loadData(true);
    },
    created() {
        if (this.$vuetify.theme.dark) {
            DxThemes.current('isDark');
        } else {
            DxThemes.current('isLight');
        };
    },
    setup() {
        loadMessages(ptMessages);
        locale(navigator.language);
        return {
            selectOnShift,
            dateFunctions
        }
    }
})
</script>

<style></style>