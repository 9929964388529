
import { defineComponent } from 'vue';
import ModalDetailsBancoCaixa from './ModalDetailsBancoCaixa.vue';


export default defineComponent({
    name: 'CardTabFluxoCaixa',
	data() {
		return {
			modalDetails: {
				title: '',
				open: false,
				typeofModal: '',
			}
		}
	},
    props: {
        saldoBanco: {
            type: String,
            required: true
        },
        saldoCaixa: {
            type: String,
            required: true
        },
        valorReceberGeral: {
            type: String,
            required: true
        },
        valorPagarGeral: {
            type: String,
            required: true
        },
        saldoGeral: {
            type: String,
            required: true
        }
    },
	methods: {
		async openModalDetailsBancoCaixa(type: 'bco' | 'cxa') {
			switch (type) {
				case 'bco':
					this.modalDetails.title = 'Saldo dos Bancos';
					break;
				case 'cxa':
					this.modalDetails.title = 'Saldo dos Caixas';
					break;
				default:
					this.$toast.error('Opção de ação inválida!');
					break;
			};

			this.modalDetails.typeofModal = type;
			this.modalDetails.open = true;
		},
		closeModalDetailsBancoCaixa() {
			this.modalDetails.open = false;	
		}
	},
	components: {
		ModalDetailsBancoCaixa
	}
});

