<template>
    <ModalCrud :loading="criarEditarLancamento.controls.isLoading" :opened="true"
        :title="criarEditarLancamento.controls.title">
        <template v-slot:body>
            <v-form ref="form" v-model="criarEditarLancamento.controls.isValid" lazy-validation>
                <v-autocomplete outlined auto-select-first clearable v-model="criarEditarLancamento.data.idCliente"
                    :items="criarEditarLancamento.datasets.clientes" :rules="formsValidations.fieldRequired"
                    item-value="id" item-text="razaoSocial" label="Cliente" required class="mt-5" />
                <v-autocomplete outlined auto-select-first clearable v-model="criarEditarLancamento.data.idColaborador"
                    :items="criarEditarLancamento.datasets.colaboradores" :rules="formsValidations.fieldRequired"
                    item-value="id" item-text="nome" label="Colaborador" required />
                <v-row cols="12">
                    <v-col md="6">
                        <v-text-field outlined v-model="criarEditarLancamento.data.qtdHoras"
                            :rules="formsValidations.timeFormatValidation" label="Quantidade de Horas" required
                            v-mask="'###:##'" placeholder="HH:MM / HHH:MM" />
                    </v-col>
                    <v-col md="6">
                        <v-text-field outlined v-model="criarEditarLancamento.data.dataInicio"
                            :rules="formsValidations.fieldRequired" label="Data de Início" type="date" required
                            class="field" />
                    </v-col>
                </v-row>
                <v-text-field outlined v-model="criarEditarLancamento.data.observacao"
                    :rules="formsValidations.fieldRequired" label="Observação" required class="field" />
                <v-autocomplete outlined auto-select-first clearable v-model="criarEditarLancamento.data.idStatus"
                    :items="criarEditarLancamento.datasets.status" :rules="formsValidations.fieldRequired"
                    item-value="id" item-text="descricao" label="Status" required />
            </v-form>
        </template>
        <template v-slot:actions>
            <v-card-actions>
                <v-btn depressed color="success" @click="createUpdate()" class="button-width-large">
                    <v-icon left>mdi-content-save-plus</v-icon>
                    Confirmar
                </v-btn>
                <v-btn depressed color="error" class="button-width-large" @click="$emit('close')">
                    <v-icon left>mdi-cancel</v-icon>
                    Cancelar
                </v-btn>
            </v-card-actions>
        </template>
    </ModalCrud>
</template>

<script>
import ModalCrud from '@/components/ModalCrud.vue';
import api from '@/api/api';
import { defineComponent } from 'vue';
import { formsValidations } from '@/utils/formsValidations';
export default defineComponent({
    name: 'ServicosControleHorasForm',
    data() {
        return {
            criarEditarLancamento: {
                data: {
                    id: null,
                    qtdHoras: '',
                    idCliente: null,
                    idColaborador: null,
                    idStatus: null,
                    dataInicio: '',
                    observacao: ''
                },
                datasets: {
                    status: [],
                    clientes: [],
                    colaboradores: []
                },
                controls: {
                    isValid: true,
                    title: '',
                    isLoading: false
                }
            }
        }
    },
    methods: {
        async createUpdate() {
            try {
                this.criarEditarLancamento.isLoading = true;
                let formValidation = await this.$refs.form.validate();
                if (!formValidation) return;
                const [method, message] = this.propId ? ['put', 'alterado'] : ['post', 'criado'];
                await api[method]('/service-time-control', this.criarEditarLancamento.data);
                this.$toast.success(`Serviço ${message} com sucesso!`);
                this.$emit('close');
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
            } finally {
                this.criarEditarLancamento.isLoading = false;
            }
        },
        async getUniqueData() {
            try {
                this.criarEditarLancamento.isLoading = true;
                const { data } = await api.get(`/service-time-control/${this.propId}`);
                const { timeControl, dataset } = data;
                this.criarEditarLancamento.datasets = {
                    ...dataset
                };
                this.criarEditarLancamento.data = {
                    ...timeControl
                };
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
                this.$emit('close');
            } finally {
                this.criarEditarLancamento.isLoading = false;
            }
        }
    },
    components: {
        ModalCrud
    },
    props: ['propId'],
    emits: ['close'],
    async created() {
        if (this.propId) {
            this.criarEditarLancamento.data.id = this.propId;
            this.criarEditarLancamento.controls.title = `${this.propId} - Editar lançamento`;
            await this.getUniqueData();
        } else {
            this.criarEditarLancamento.controls.title = 'Novo lançamento';
        };

        try {
            this.criarEditarLancamento.isLoading = true;
            const { data } = await api.get('/service-time-control?data=true');
            const { dataset } = data;
            this.criarEditarLancamento.datasets = {
                ...dataset
            };
        } catch (error) {
            this.$toast.error(error.message ?? 'Falha na comunicação');
        } finally {
            this.criarEditarLancamento.isLoading = false;
        }
    },
    setup() {
        return {
            formsValidations
        }
    }
})
</script>

<style></style>