<template>
	<v-container fluid fill-height>
		<v-layout column justify-center align-center>
			<div class="full-width">
				<v-row>
					<v-col md="6">
						<div class="pa-3">
							<v-card outlined class="full-width">
								<v-list-item two-line>
									<v-list-item-content>
										<div class="d-flex justify-space-between align-center text-overline mb-6">
											Saldo dinheiro
											<v-icon v-if="this.$vuetify.breakpoint.mdAndUp" large
												color="info">mdi-cash</v-icon>
										</div>
										<v-list-item-title class="text-h5">
											R$ {{ cards.datasetHeader.saldoDinheiro }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-avatar v-if="this.$vuetify.breakpoint.smAndDown" tile size="80">
										<v-icon large color="info">mdi-cash</v-icon>
									</v-list-item-avatar>
								</v-list-item>
							</v-card>
						</div>
					</v-col>
					<v-col md="6">
						<div class="pa-3">
							<v-card outlined class="full-width">
								<v-list-item two-line>
									<v-list-item-content>
										<div class="d-flex justify-space-between align-center text-overline mb-6">
											Saldo cartão
											<v-icon v-if="this.$vuetify.breakpoint.mdAndUp" large
												color="info">mdi-credit-card</v-icon>
										</div>
										<v-list-item-title class="text-h5">
											R$ {{ cards.datasetHeader.saldoCartao }}
										</v-list-item-title>
									</v-list-item-content>
									<v-list-item-avatar v-if="this.$vuetify.breakpoint.smAndDown" tile size="80">
										<v-icon large color="info">mdi-credit-card</v-icon>
									</v-list-item-avatar>
								</v-list-item>
							</v-card>
						</div>
					</v-col>
					<v-col cols="12">
						<div class="pa-3">
							<v-text-field outlined v-model="search" label="Buscar..." required
								class="field"></v-text-field>
						</div>
					</v-col>
				</v-row>
			</div>
			<v-row align-content="start" cols="12" class="full-width" fill-height
				:style="checkMobileScreen ? `display: block; height: ${(filteredDataset.length * 270) + 10}px` : ``">
				<v-col v-for="(x, i) in filteredDataset" :key="i" md="3" style="height: 265px;" class="my-1">
					<v-card style="height: 265px;">
						<v-card-title>
							<span class="subtitle-2" :class="{ 'red--text': x.idStatus === 'I' }">{{ x.tipo }}</span>
							<v-spacer></v-spacer>

							<div v-if="x.operacao === 'C'">
								<v-icon color="success" v-if="x.operador === 'C'">
									mdi-credit-card-plus-outline
								</v-icon>
								<v-icon color="error" v-else>
									mdi-credit-card-minus-outline
								</v-icon>
							</div>
							<div v-else>
								<v-icon color="success" v-if="x.operador === 'C'">
									mdi-cash-plus
								</v-icon>
								<v-icon color="error" v-else>
									mdi-cash-minus
								</v-icon>
							</div>
							<v-menu bottom left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="mx-2" fab dark small text color="primary" v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>

								<v-list dense>
									<v-list-item ripple @click="showModalNewEdit(x.id)" v-if="x.idStatus === 'A'">
										<v-list-item-icon>
											<v-icon color="primary">mdi-pencil</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Alterar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item ripple @click="activeInactive(x.id, x.idStatus)">
										<v-list-item-icon>
											<v-icon :color="x.idStatus === 'A' ? 'error' : 'positive'">{{ x.idStatus ===
												'A' ? 'mdi-trash-can-outline' :
												'mdi-check-all' }}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{ x.idStatus === 'A' ? 'Inativar' : 'Reativar'
												}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>

								</v-list>
							</v-menu>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-row cols="12">
								<v-col md="12" cols="12">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
												Resp.: {{ x.responsavel }}
											</div>
										</template>
										<div class="font-weight-black text-truncate">
											Resp.: {{ x.responsavel }}
										</div>
									</v-tooltip>
								</v-col>
								<v-col md="12" cols="12">
									<div class="font-weight-black">Valor: R$ {{ x.valor }}</div>
								</v-col>
								<v-col md="12" cols="12">
									<div class="font-weight-black">Data: {{ x.data }}</div>
								</v-col>
							</v-row>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-text>
							<v-row cols="12">
								<v-col md="12">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
												Obs.: {{ x.obs }}
											</div>
										</template>
										<div class="font-weight-black">Obs.: {{ x.obs }}</div>
									</v-tooltip>

								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-speed-dial fab dark large color="primary" fixed right bottom v-model="fab" transtion="scale"
				direction="top" :top="false" :left="false" :open-on-hover="false">
				<template v-slot:activator>
					<v-btn v-model="fab" :color="fab ? 'red' : 'success'" fab>
						<v-icon v-if="fab">
							mdi-dots-horizontal
						</v-icon>
						<v-icon v-else>
							mdi-dots-vertical
						</v-icon>
					</v-btn>
				</template>
				<v-btn fab dark small color="indigo" @click="showModalNewEdit(null)">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
				<v-btn fab dark small color="green" @click="xlsxExport()">
					<v-icon>mdi-microsoft-excel</v-icon>
				</v-btn>
				<v-btn fab dark small color="orange" @click="showModalFiltrarLancamento()">
					<v-icon>mdi-filter</v-icon>
				</v-btn>
			</v-speed-dial>
		</v-layout>
		<ModalCrud :loading="modals.criarEditarLancamento.isLoading" :opened="modals.criarEditarLancamento.visible"
			:title="modals.criarEditarLancamento.title">
			<template v-slot:body>
				<v-form ref="form" v-model="forms.criarEditarLancamento.controls.isValid" lazy-validation>
					<div class="mt-5">
						<div style="margin-bottom: -15px;">Responsável</div>
						<v-radio-group @change="() => forms.criarEditarLancamento.data.idCliente = null"
							v-model="forms.criarEditarLancamento.data.responsavel" row>
							<v-radio label="Cliente" value="C"></v-radio>
							<v-radio label="Empresa" value="E"></v-radio>
						</v-radio-group>
					</div>
					<div v-if="forms.criarEditarLancamento.data.responsavel === 'C'">
						<v-autocomplete outlined auto-select-first clearable
							v-model="forms.criarEditarLancamento.data.idCliente"
							:items="forms.criarEditarLancamento.datasets.clientes"
							:rules="formsValidations.fieldRequired" item-value="id" item-text="nomeFantasia"
							label="Cliente" class="" required></v-autocomplete>
					</div>
					<div>
						<div style="margin-bottom: -15px;">Cartão/Dinheiro</div>
						<v-radio-group v-model="forms.criarEditarLancamento.data.operacao" row>
							<v-radio label="Cartão" value="C"></v-radio>
							<v-radio label="Dinheiro" value="D"></v-radio>
						</v-radio-group>
					</div>
					<div
						v-if="$store.state.infoUser.current.supervisor || $store.state.infoUser.current.departamento === 4">
						<div style="margin-bottom: -15px;">Operação no Lote</div>
						<v-radio-group v-model="forms.criarEditarLancamento.data.operador" row>
							<v-radio label="Crédito" value="C"></v-radio>
							<v-radio label="Débito" value="D"></v-radio>
						</v-radio-group>
					</div>
					<v-autocomplete outlined auto-select-first clearable
						v-model="forms.criarEditarLancamento.data.idTipo"
						:items="forms.criarEditarLancamento.datasets.tipos" :rules="formsValidations.fieldRequired"
						item-value="id" item-text="descricao" label="Tipo" class="" required></v-autocomplete>
					<v-row cols="12">
						<v-col md="6">
							<v-currency-field ref="refCurrencyField" label="Valor"
								:rules="formsValidations.valueRequired" outlined
								v-model="forms.criarEditarLancamento.data.valor" />
						</v-col>
						<v-col md="6">
							<v-text-field outlined v-model="forms.criarEditarLancamento.data.data" :rules="formsValidations.fieldRequired"
								label="Data" type="date">
							</v-text-field>
						</v-col>
					</v-row>
					<v-text-field outlined v-model="forms.criarEditarLancamento.data.obs" label="Descrição" required class="field"></v-text-field>
				</v-form>
			</template>
			<template v-slot:actions>
				<v-card-actions>
					<v-btn depressed color="success" @click="saveControleFinanceiroMovimentacao()"
						class="button-width-large">
						<v-icon left>mdi-content-save-plus</v-icon>
						Confirmar
					</v-btn>
					<v-btn depressed color="error" class="button-width-large" @click="destroyModalNewEdit()">
						<v-icon left>mdi-cancel</v-icon>
						Cancelar
					</v-btn>
				</v-card-actions>
			</template>
		</ModalCrud>
		<ModalCrud :loading="modals.filtrarLancamentos.isLoading" :opened="modals.filtrarLancamentos.visible"
			:title="modals.filtrarLancamentos.title">
			<template v-slot:body>
				<v-form ref="form" v-model="forms.filtrarLancamentos.controls.isValid" lazy-validation>
					<div class="mt-5">
						<v-autocomplete outlined auto-select-first clearable
							v-model="forms.filtrarLancamentos.data.operador" :items="[
								{
									id: 'C',
									label: 'Crédito'
								},
								{
									id: 'D',
									label: 'Débito'
								}
							]" item-value="id" item-text="label" label="Operador" required />
					</div>
					<div>
						<v-autocomplete outlined auto-select-first clearable
							v-model="forms.filtrarLancamentos.data.operacao" :items="[
								{
									id: 'C',
									label: 'Cartão'
								},
								{
									id: 'D',
									label: 'Dinheiro'
								}
							]" item-value="id" item-text="label" label="Operação" required />
					</div>
					<div>
						<v-autocomplete outlined auto-select-first clearable
							v-model="forms.filtrarLancamentos.data.responsavel" :items="[
								{
									id: 'C',
									label: 'Cliente'
								},
								{
									id: 'E',
									label: 'Empresa'
								}
							]" item-value="id" item-text="label" label="Responsável" required />
					</div>
					<div>
						<v-autocomplete outlined auto-select-first clearable
							v-model="forms.filtrarLancamentos.data.status" :items="[
								{
									id: 'A',
									label: 'Ativos'
								},
								{
									id: 'I',
									label: 'Inativos'
								}
							]" item-value="id" item-text="label" label="Status" required />
					</div>
					<v-row cols="12">
						<v-col md="6">
							<v-text-field type="date" outlined v-model="forms.filtrarLancamentos.data.dataInicial"
								label="Data Inicial" required class="field" />
						</v-col>
						<v-col md="6">
							<v-text-field type="date" outlined v-model="forms.filtrarLancamentos.data.dataFinal"
								label="Data Final" required class="field" />
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:actions>
				<v-card-actions>
					<v-btn depressed color="success" @click="handleFilter()" class="button-width-large">
						<v-icon left>mdi-content-save-plus</v-icon>
						Confirmar
					</v-btn>
					<v-btn depressed color="error" class="button-width-large"
						@click="modals.filtrarLancamentos.visible = false">
						<v-icon left>mdi-cancel</v-icon>
						Cancelar
					</v-btn>
				</v-card-actions>
			</template>
		</ModalCrud>
	</v-container>

</template>

<script>
import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import { formsValidations } from '@/utils/formsValidations';
import { dateFunctions } from '@/utils/dateFunctions';
import { XlsxExport } from '@/utils/xlsxExport';

export default defineComponent({
	name: 'ControleFinanceiroMovimentacao',
	data() {
		return {
			cards: {
				datasetHeader: {
					nome: '',
					saldoCartao: '',
					saldoDinheiro: ''
				},
				dataset: [],
				isLoading: false
			},
			fab: false,
			modals: {
				criarEditarLancamento: {
					isLoading: false,
					visible: false,
					title: ''
				},
				filtrarLancamentos: {
					isLoading: false,
					visible: false,
					title: 'Filtrar'
				}
			},
			forms: {
				criarEditarLancamento: {
					data: {
						id: null,
						operador: '',
						operacao: '',
						idControleFinanceiro: '',
						valor: null,
						responsavel: '',
						obs: '',
						data: '',
						idCliente: null,
						idTipo: '',
						idStatus: 1
					},
					datasets: {
						tipos: [],
						clientes: []
					},
					controls: {
						isValid: true
					}
				},
				filtrarLancamentos: {
					controls: {
						isValid: true
					},
					data: {
						operacao: '',
						operador: '',
						responsavel: '',
						dataInicial: '',
						dataFinal: '',
						status: 'A'
					},

				}
			},
			search: '',
		};
	},
	computed: {
		checkMobileScreen() {
			const check = this.isMobile;
			return check;
		},
		filteredDataset() {
			let lowerSearch = this.search.toLowerCase();
			return this.cards.dataset.filter(x =>
				x.data.toLowerCase().includes(lowerSearch) ||
				x.valor.toLowerCase().includes(lowerSearch) ||
				x.obs.toLowerCase().includes(lowerSearch) ||
				x.responsavel.toLowerCase().includes(lowerSearch) ||
				x.tipo.toLowerCase().includes(lowerSearch)
			)
		}
	},
	methods: {
		async showModalNewEdit(id) {
			try {
				let response;

				this.modals.criarEditarLancamento.isLoading = true;
				this.modals.criarEditarLancamento.visible = true;
				this.modals.criarEditarLancamento.title = id ? `${id} - Editar Lançamento ` : 'Novo lançamento';
				response = await api.get('/financial-control-batch-data');
				this.forms.criarEditarLancamento.datasets.tipos = response.data.tipos;
				this.forms.criarEditarLancamento.datasets.clientes = response.data.clientes;
				if (id) {
					response = await api.get(`/financial-control-batch/${id}`);
					this.forms.criarEditarLancamento.data = { ...this.forms.criarEditarLancamento.data, ...response.data };
					this.$refs.refCurrencyField.setValue(response.data.valor);
				}
				else {
					this.forms.criarEditarLancamento.data = {
						id: null,
						operador: '',
						operacao: '',
						idControleFinanceiro: '',
						valor: null,
						responsavel: '',
						obs: '',
						idTipo: ''
					};
					if (!(this.$store.state.infoUser.current.supervisor) && !(this.$store.state.infoUser.current.departamento === 4)) {
						this.forms.criarEditarLancamento.data.operador = 'D'
					}
				}
			}
			catch (error) {
				this.$toast.error(error.message ?? 'Falha na comunicação');
			}
			finally {
				this.modals.criarEditarLancamento.isLoading = false;
				this.$refs.form.resetValidation();
			}
		},
		async showModalFiltrarLancamento() {
			try {
				this.modals.filtrarLancamentos.isLoading = true;
				this.modals.filtrarLancamentos.visible = true;
			} catch (error) {
				this.$toast.error(error.message ?? 'Falha na comunicação');
			} finally {
				this.modals.filtrarLancamentos.isLoading = false;
			}
		},
		async saveControleFinanceiroMovimentacao() {
			try {
				let formValidation = await this.$refs.form.validate();

				if (this.forms.criarEditarLancamento.data.responsavel !== 'C' && this.forms.criarEditarLancamento.data.responsavel !== 'E') throw new Error("Escolha um responsável!");

				if (this.forms.criarEditarLancamento.data.operacao !== 'C' && this.forms.criarEditarLancamento.data.operacao !== 'D') throw new Error("Escolha entre cartão e dinheiro.");

				if (this.forms.criarEditarLancamento.data.operador !== 'C' && this.forms.criarEditarLancamento.data.operador !== 'D') throw new Error("Escolha entre créditar ou debitar o lote.");

				if (formValidation) {
					this.modals.criarEditarLancamento.isLoading = true;
					this.forms.criarEditarLancamento.data.idControleFinanceiro = Number(this.$router.currentRoute.params.id);
					const [metodo, mensagem] = this.forms.criarEditarLancamento.data.id ? ['put', 'alterada'] : ['post', 'criada'];
					await api[metodo]('/financial-control-batch', this.forms.criarEditarLancamento.data);
					this.$toast.success(`Movimentação ${mensagem} com sucesso!`);
					this.modals.criarEditarLancamento.visible = false;
					await this.loadData();
				} else {
					throw new Error("Formulário inválido.")
				}
			}
			catch (error) {
				this.$toast.error(error.message ?? 'Falha na comunicação');
			}
			finally {
				this.modals.criarEditarLancamento.isLoading = false;
			}
		},
		async handleFilter() {
			try {
				this.modals.filtrarLancamentos.isLoading = true;
				await this.loadData();
				this.modals.filtrarLancamentos.visible = false;
			} catch (error) {
				this.$toast.error(error.message ?? 'Falha ao filtrar dados.')
			} finally {
				this.modals.filtrarLancamentos.isLoading = false;
			}
		},
		async loadData() {
			try {
				this.cards.isLoading = true;
				const { data } = await api.get(`/financial-control-batch?idLote=${this.$router.currentRoute.params.id}${this.gerarQueryString() ? `&${this.gerarQueryString()}` :
					''}`);
				this.cards.dataset = data.data;
				this.cards.datasetHeader = {
					...data.dataLote
				};
				store.commit(M_MENU, `Controle Financeiro - Lote ${data.dataLote.nome}`);
			} catch (err) {
				console.log(err)
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.cards.isLoading = false;
			}
		},
		async activeInactive(id, idStatus) {
			try {
				await api.patch(`/financial-control-batch/${id}`);

				await this.loadData();

				this.$toast.success(`Registro ${idStatus === 'A' ? 'inativado' : 'reativado'} com sucesso!`)
			}
			catch (error) {
				this.$toast.error(error.message ?? 'Falha na comunicação');
			}
		},
		destroyModalNewEdit() {
			this.forms.criarEditarLancamento.data = {
				id: null,
				operador: '',
				operacao: '',
				idControleFinanceiro: '',
				valor: null,
				responsavel: '',
				obs: '',
				idTipo: ''
			};
			this.modals.criarEditarLancamento.visible = false;
			this.forms.criarEditarLancamento.datasets.clientes = [];
			this.forms.criarEditarLancamento.datasets.tipos = [];
		},
		async xlsxExport() {
			try {
				const prepareData = this.filteredDataset.map(x => {					
					return {
						operador: x.operador === 'C' ? 'Crédito' : 'Débito',
						operacao: x.operacao === 'C' ? 'Cartão' : 'Dinheiro',
						data: x.data,
						valor: Number(x.valor.replaceAll('.', '#').replaceAll(',', '.').replaceAll('#', '')),
						tipo: x.tipo,
						responsavel: x.responsavel,
						obs: x.obs,
						idStatus: x.idStatus === 'A' ? 'Ativo' : 'Inativo'

					}
				})
				let instanceExport = new XlsxExport({
					columns: [
						{
							name: 'operador',
							label: 'Operador',
						},
						{
							name: 'operacao',
							label: 'Operação',
						},
						{
							name: 'data',
							label: 'Data'
						},
						{
							name: 'valor',
							label: 'Valor',
							isCurrency: true
						},
						{
							name: 'tipo',
							label: 'Tipo'
						},
						{
							name: 'responsavel',
							label: 'Resp.'
						},
						{
							name: 'obs',
							label: 'Obs.'
						},
						{
							name: 'idStatus',
							label: 'Status'
						}
					],
					data: prepareData,
					title: `Controle Fin. ${this.cards.datasetHeader.nome}`
				});
				await instanceExport.export();
				instanceExport = null;
			} catch (error) {
				this.$toast.error(error.message ?? 'Falha ao comunicar com o banco de dados!');
			}
		},
		gerarQueryString() {
			const query = Object.keys(this.forms.filtrarLancamentos.data)
				.filter(key => this.forms.filtrarLancamentos.data[key] !== '' && this.forms.filtrarLancamentos.data[key] !== null && this.forms.filtrarLancamentos.data[key] !== undefined)
				.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.forms.filtrarLancamentos.data[key]))
				.join('&');

			return query;
		}
	},
	async mounted() {
		await this.loadData();
	},
	setup() {
		return {
			formsValidations
		}
	},
	components: { ModalCrud }
})

</script>
