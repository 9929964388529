export const formsValidations = {
    fieldRequired: [
        v => !!v || 'Esse campo precisa ser preenchido.'
    ],
    valueRequired: [
        v => (!!v && !(Number(v.replaceAll('.', '').replaceAll(',', '.')) <= 0)) || 'Esse campo não pode ficar zerado.',
        v => !!v || 'Esse campo precisa ser preenchido.'
    ],
    lengthRequired: (length: number) => {
        return v => (v && v.length < length) || 'Descrição não pode ter mais que 50 caracteres.'
    },
    requiredAndLength: (length: number) => {
        return [
            v => !!v || 'Descrição precisa ser preenchida.',
            v => (v && v.length < 50) || 'Descrição não pode ter mais que 50 caracteres.'
        ]
    },
    emailRequiredAndValidation: () => {
        return [
            v => !!v || 'E-mail precisa ser preenchido.',
            v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido!',
        ]
    },
    timeFormatValidation: [
        v => {
            if (!v) return 'Esse campo precisa ser preenchido.';
            const regex = /^\d{2,3}:\d{2}$/; // Valida formato HH:MM ou HHH:MM
            if (!regex.test(v)) {
                return 'Formato inválido. Use HH:MM ou HHH:MM.';
            }
            const [horas, minutos] = v.split(':').map(Number);
            if (isNaN(horas) || isNaN(minutos)) {
                return 'Formato inválido. Use HH:MM.';
            }
            if (minutos < 0 || minutos >= 60) {
                return 'Minutos devem estar entre 00 e 59.';
            }
            return true;
        }
    ]
}