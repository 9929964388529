
import { defineComponent } from 'vue';
import { columns } from '@/utils/columns';
import Grid from '@/components/Grid.vue';
import api from '@/api/api';

type TTotalizadorGrid = {
	saldoCxa: string;
	saldoCC: string;
	saldoAplicacao: string;
	saldoTotal: string;
};

export default defineComponent({
	name: 'ModalDetailsBancoCaixa',
	data() {
		return {
			show: false,
			loading: false,
			columns: [],
			dataset: [],
			total: {} as TTotalizadorGrid,
		}
	},
	props: {
		title: {
			type: String,
			required: true
		},
		opened: {
			type: Boolean,
			required: true
		},
		typeofModal: {
			type: String,
			required: true
		}
	},
	methods: {
		async loadData() {
			try {
				this.loading = true;
				const { data } = await api.get(`/dash/saldoBancosCaixas?typeofModal=${this.typeofModal}`);
				if (data) {
					this.dataset = data?.dataset;
					this.total = data?.total;
				};
			} catch (error) {
				this.$toast.error('Falha ao carregar os dados!');
			} finally {
				this.loading = false;
			}
		}
	},
	mounted() {
		this.show = this.opened;
		this.columns = this.typeofModal === 'bco' ? columns.dashboards.saldoBancos : columns.dashboards.saldoCaixas;
		this.loadData();
	},
	components: {
		Grid
	}
})
