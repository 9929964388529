<template>
    <v-container fluid fill-height>
        <v-layout column justify-center align-center>
            <div class="full-width">
                <v-row>
                    <v-col md="6">
                        <div class="pa-0">
                            <v-card outlined class="full-width">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <div class="d-flex justify-space-between align-center text-overline mb-6">
                                            Cliente
                                            <v-icon v-if="this.$vuetify.breakpoint.mdAndUp" large
                                                color="info">mdi-account-tie</v-icon>
                                        </div>
                                        <v-list-item-title class="text-h5">
                                            {{ resume.cliente }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-avatar v-if="this.$vuetify.breakpoint.smAndDown" tile size="80">
                                        <v-icon large color="info">mdi-account-tie</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col md="6">
                        <div class="pa-0">
                            <v-card outlined class="full-width">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <div class="d-flex justify-space-between align-center text-overline mb-6">
                                            Saldo de Horas
                                            <v-icon v-if="this.$vuetify.breakpoint.mdAndUp" large
                                                color="info">mdi-calendar-clock</v-icon>
                                        </div>
                                        <v-list-item-title class="text-h5">
                                            {{ resume.saldo }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-avatar v-if="this.$vuetify.breakpoint.smAndDown" tile size="80">
                                        <v-icon large color="info">mdi-calendar-clock</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="pa-3">
                            <v-text-field outlined v-model="search" label="Buscar..." required
                                class="field"></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-row align-content="start" cols="12" class="full-width" fill-height
                :style="checkMobileScreen ? `display: block; height: ${(filteredDataset.length * 320) + 10}px` : ``">
                <v-col v-for="(x, i) in filteredDataset" :key="i" md="3"
                    :style="checkMobileScreen ? 'height: 320px;' : 'height: 240px;'" class="my-1">
                    <v-card :style="checkMobileScreen ? 'height: 320px;' : 'height: 240px;'">
                        <v-card-title>
                            <span class="subtitle-2" :class="{ 'red--text': x.idStatus === 'I' }">{{ x.descricao
                                }}</span>
                            <v-spacer></v-spacer>
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2" fab dark small text color="primary" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item ripple @click="showModalNewEdit(x.id)" v-if="x.idStatus === 'A'">
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Alterar</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item ripple @click="activeInactive(x.id)">
                                        <v-list-item-icon>
                                            <v-icon :color="x.idStatus === 'A' ? 'error' : 'positive'">{{ x.idStatus ===
                                                'A' ? 'mdi-trash-can-outline' :
                                                'mdi-check-all' }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ x.idStatus === 'A' ? 'Inativar' : 'Reativar'
                                                }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row cols="12">
                                <v-col md="12" cols="12">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
                                                Resp.: {{ x.responsavel }}
                                            </div>
                                        </template>
                                        <div class="font-weight-black text-truncate">
                                            Resp.: {{ x.responsavel }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col md="6" cols="12">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
                                                Ticket: {{ x.nTicket }}
                                            </div>
                                        </template>
                                        <div class="font-weight-black text-truncate">
                                            Ticket: {{ x.nTicket }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col md="6" cols="12">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
                                                Horas: {{ x.qtdHoras }}
                                            </div>
                                        </template>
                                        <div class="font-weight-black text-truncate">
                                            Horas: {{ x.qtdHoras }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col xs="6" sm="6" md="6" cols="12">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
                                                Início: {{ x.dataInicio }}
                                            </div>
                                        </template>
                                        <div class="font-weight-black text-truncate">
                                            Início: {{ x.dataInicio }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                                <v-col xs="6" sm="6" md="6" cols="12">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="font-weight-black text-truncate" v-bind="attrs" v-on="on">
                                                Fim: {{ x.dataFim }}
                                            </div>
                                        </template>
                                        <div class="font-weight-black text-truncate">
                                            Fim: {{ x.dataFim }}
                                        </div>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-speed-dial fab dark large color="primary" fixed right bottom v-model="fab" transtion="scale"
                direction="top" :top="false" :left="false" :open-on-hover="false">
                <template v-slot:activator>
                    <v-btn v-model="fab" :color="fab ? 'red' : 'success'" fab>
                        <v-icon v-if="fab">
                            mdi-dots-horizontal
                        </v-icon>
                        <v-icon v-else>
                            mdi-dots-vertical
                        </v-icon>
                    </v-btn>
                </template>
                <v-btn fab dark small color="indigo" @click="showModalNewEdit(null)">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <!--                 <v-btn fab dark small color="green" @click="xlsxExport()">
                    <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-btn fab dark small color="orange" @click="showModalFiltrarLancamento()">
                    <v-icon>mdi-filter</v-icon>
                </v-btn> -->
            </v-speed-dial>
        </v-layout>
        <ModalCrud :loading="modals.criarEditarLancamento.isLoading" :opened="modals.criarEditarLancamento.visible"
            :title="modals.criarEditarLancamento.title" v-if="modals.criarEditarLancamento.visible">
            <template v-slot:body>
                <v-form ref="form" v-model="forms.criarEditarLancamento.controls.isValid" lazy-validation>
                    <div class="mt-5">
                        <v-autocomplete outlined auto-select-first clearable
                            v-model="forms.criarEditarLancamento.data.idColaborador"
                            :items="forms.criarEditarLancamento.datasets.colaboradores"
                            :rules="formsValidations.fieldRequired" item-value="id" item-text="nome" label="Colaborador"
                            class="" required></v-autocomplete>
                    </div>
                    <v-row cols="12">
                        <v-col md="6">
                            <v-text-field outlined v-model="forms.criarEditarLancamento.data.nTicket"
                                :rules="formsValidations.fieldRequired" label="Ticket" type="text">
                            </v-text-field>
                        </v-col>
                        <v-col md="6">
                            <v-text-field outlined v-model="forms.criarEditarLancamento.data.qtdHoras"
                                :rules="formsValidations.timeFormatValidation" label="Quantidade de Horas" required
                                v-mask="'###:##'" placeholder="HH:MM / HHH:MM" />
                        </v-col>
                    </v-row>
                    <v-row cols="12" class="mt-0">
                        <v-col md="6">
                            <v-text-field outlined v-model="forms.criarEditarLancamento.data.dataInicio"
                                :rules="formsValidations.fieldRequired" label="Data Início" type="date" required
                                class="field" />
                        </v-col>
                        <v-col md="6">
                            <v-text-field outlined v-model="forms.criarEditarLancamento.data.dataFim"
                                :rules="formsValidations.fieldRequired" label="Data Fim" type="date" required
                                class="field" />
                        </v-col>
                    </v-row>
                    <v-text-field outlined v-model="forms.criarEditarLancamento.data.descricao"
                        :rules="formsValidations.fieldRequired" label="Descrição" required class="field"></v-text-field>
                    <div>
                        <div style="margin-bottom: -15px;">Baixa Saldo</div>
                        <v-radio-group v-model="forms.criarEditarLancamento.data.baixaSaldo" row>
                            <v-radio label="Sim" :value="1"></v-radio>
                            <v-radio label="Não" :value="0"></v-radio>
                        </v-radio-group>
                    </div>

                </v-form>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn depressed color="success" @click="createUpdate()" class="button-width-large">
                        <v-icon left>mdi-content-save-plus</v-icon>
                        Confirmar
                    </v-btn>
                    <v-btn depressed color="error" class="button-width-large"
                        @click="handleDestroyModals('createUpdate')">
                        <v-icon left>mdi-cancel</v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </template>
        </ModalCrud>
    </v-container>
</template>

<script>
import ModalCrud from '@/components/ModalCrud.vue';
import api from '@/api/api';
import { defineComponent } from 'vue';
import { formsValidations } from '@/utils/formsValidations';
import { M_MENU } from '@/store/mutations-types';
import { store } from '@/store';
export default defineComponent({
    name: 'ServicoControleHorasMovimentacao',
    data() {
        return {
            search: '',
            isLoading: false,
            dataset: [],
            params: {
                dataInicio: null,
                dataFim: null,
                idColaborador: null,
                idStatus: null
            },
            fab: false,
            resume: {
                cliente: '',
                saldo: ''
            },
            modals: {
                criarEditarLancamento: {
                    isLoading: false,
                    visible: false,
                    title: ''
                },
                filtrarLancamentos: {
                    isLoading: false,
                    visible: false,
                    title: 'Filtrar'
                }
            },
            forms: {
                criarEditarLancamento: {
                    data: {
                        id: null,
                        nTicket: '',
                        qtdHoras: '',
                        descricao: '',
                        dataInicio: '',
                        dataFim: '',
                        baixaSaldo: 1,
                        idColaborador: null
                    },
                    datasets: {
                        colaboradores: []
                    },
                    controls: {
                        isValid: true
                    }
                },
                filtrarLancamentos: {
                    data: {
                        dataInicio: null,
                        dataFim: null,
                        idColaborador: null,
                        idStatus: null
                    },
                    datasets: {
                        colaboradores: []
                    },
                    controls: {
                        isValid: true
                    }
                }
            }
        }
    },
    methods: {
        async loadData() {
            try {
                this.$store.state.loading = true;
                const { data } = await api.get('/service-time-control-mov', {
                    params: {
                        ...this.params,
                        idControleHoras: this.$router.currentRoute.params.id
                    }
                });                
                this.dataset = data.serviceTimeControlMovs;
                this.resume = data.resume[0];
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
            } finally {
                this.$store.state.loading = false;
            }
        },
        async showModalNewEdit(id) {
            try {
                this.$store.state.loading = true;
                this.modals.criarEditarLancamento.isLoading = true;
                this.modals.criarEditarLancamento.visible = true;
                this.modals.criarEditarLancamento.title = id ? `${id} - Editar Lançamento ` : 'Novo lançamento';

                if (id) {
                    const { data } = await api.get(`/service-time-control-mov/${id}`);
                    const { timeControlMov, dataset } = data;
                    this.forms.criarEditarLancamento.datasets.colaboradores = dataset.colaboradores;
                    this.forms.criarEditarLancamento.data = {
                        ...timeControlMov,
                        qtdHoras: timeControlMov.qtdHoras.slice(0, 6)
                    };
                } else {
                    const { data } = await api.get('/service-time-control-mov?data=true');
                    const { dataset } = data;
                    this.forms.criarEditarLancamento.datasets.colaboradores = dataset.colaboradores;
                }
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
            } finally {
                this.$store.state.loading = false;
                this.modals.criarEditarLancamento.isLoading = false;
            }
        },
        xlsxExport() {

        },
        async activeInactive(id) {
            try {
                this.$store.state.loading = true;
                await api.patch(`/service-time-control-mov/${id}`);
                await this.loadData();
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
            } finally {
                this.$store.state.loading = false;
            }
        },
        showModalFiltrarLancamento() {

        },
        async createUpdate() {
            try {
                this.$store.state.loading = true;
                this.modals.criarEditarLancamento.isLoading = true;
                let formValidation = await this.$refs.form.validate();
                if (formValidation) {
                    const [metodo, mensagem] = this.forms.criarEditarLancamento.data.id ? ['put', 'alterada'] : ['post', 'criada'];
                    await api[metodo]('/service-time-control-mov', {
                        ...this.forms.criarEditarLancamento.data,
                        idControleHoras: Number(this.$router.currentRoute.params.id)
                    });
                    this.$toast.success(`Movimentação ${mensagem} com sucesso!`);
                    await this.loadData();
                    this.handleDestroyModals('createUpdate')
                } else {
                    throw new Error("Formulário inválido.")
                }
            } catch (error) {
                this.$toast.error(error.message ?? 'Falha na comunicação');
            } finally {
                this.$store.state.loading = false;
                this.modals.criarEditarLancamento.isLoading = false;
            }
        },
        handleDestroyModals(modal) {
            switch (modal) {
                case 'createUpdate':
                    this.modals.criarEditarLancamento.isLoading = false;
                    this.modals.criarEditarLancamento.title = '';
                    this.modals.criarEditarLancamento.visible = false;
                    this.forms.criarEditarLancamento.data = {
                        id: null,
                        nTicket: '',
                        qtdHoras: '',
                        descricao: '',
                        dataInicio: '',
                        dataFim: '',
                        baixaSaldo: 1,
                        idColaborador: null
                    }
                    this.forms.criarEditarLancamento.datasets.colaboradores = [];
                    this.forms.criarEditarLancamento.controls.isValid = true;
                    break;
                case 'filter':
                    this.modals.filtrarLancamentos.isLoading = false;
                    this.modals.filtrarLancamentos.title = 'Filtrar';
                    this.modals.filtrarLancamentos.visible = false;
                    this.forms.filtrarLancamentos.data = {
                        dataInicio: '',
                        dataFim: '',
                        idColaborador: null,
                        idStatus: null
                    }
                    this.forms.filtrarLancamentos.datasets.colaboradores = [];
                    break;
            }
        }
    },
    computed: {
        checkMobileScreen() {
            const check = this.isMobile;
            return check;
        },
        filteredDataset() {
            let lowerSearch = this.search.toLowerCase();            
            return this.dataset.filter(x =>                
                x.nTicket.toLowerCase().includes(lowerSearch) ||
                x.qtdHoras.toLowerCase().includes(lowerSearch) ||
                x.descricao.toLowerCase().includes(lowerSearch) ||
                x.dataInicio.toLowerCase().includes(lowerSearch) ||
                x.dataFim.toLowerCase().includes(lowerSearch) ||
                x.responsavel.toLowerCase().includes(lowerSearch) ||
                x.baixaSaldo.toLowerCase().includes(lowerSearch)
            )
        }
    },
    components: {
        ModalCrud
    },
    async mounted() {
        store.commit(M_MENU, `Registro de Serviço - ${this.$router.currentRoute.params.id}`);
        await this.loadData();
    },
    setup() {
        return {
            formsValidations
        }
    }
})

</script>