<template>
    <v-container fluid>
        <v-layout justify-center align-center>
            <Grid :headers="columns.lancamentos.controleServicos" :items="table.dataset" :loading="table.isLoading"
                :items-actions="table.gridItemsActions" @items-actions="this.handleGridItemsActions">
                <template v-slot:[`right.top`]>
                    <v-btn color="primary" dark class="mb-2" @click="handleModal('open')">
                        <v-icon left dark>mdi-plus</v-icon>
                        Novo
                    </v-btn>
                </template>
                <template v-slot:[`item.status`]="{ item }">                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="40" v-bind="attrs" v-on="on">
                                <v-icon :color="item.status.color">{{ item.status.icon }}</v-icon>
                            </v-avatar>
                        </template>
                        <span>{{ item.status.descricao }}</span>
                    </v-tooltip>
                    <span>{{ item.status.descricao }}</span>
                </template>
            </Grid>
        </v-layout>        
        <ServicosControleHorasForm :propId="selectedId" v-if="modals.criarEditarLancamento.isOpened"
            @close="handleModal('close')" />
    </v-container>
</template>
<script>
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { columns } from '@/utils/columns';
import { defineComponent } from 'vue';
import Grid from '@/components/Grid.vue';
import api from '@/api/api';
import ServicosControleHorasForm from './ServicosControleHorasForm.vue';

export default defineComponent({
    name: 'ServicosControleHoras',
    data() {
        return {
            table: {
                isLoading: false,
                dataset: [],
                gridItemsActions: [
                    {
                        label: 'Alterar',
                        action: 'update',
                        icon: 'mdi-pencil',
                        color: 'primary',
                        display: true,
                        loading: false
                    },
                    {
                        label: 'Ver Serviços',
                        action: 'see-service',
                        icon: 'mdi-eye',
                        color: 'purple accent-4',
                        display: true,
                        loading: false
                    }

                ]
            },
            modals: {
                criarEditarLancamento: {
                    isOpened: false
                },
                verServico: {
                    isOpened: false
                }
            },
            selectedId: null
        }
    },
    async mounted() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            try {
                this.table.isLoading = true;
                const { data } = await api.get('/service-time-control');
                this.table.dataset = data;
                store.commit(M_MENU, "Controle de Serviços");
            } catch (err) {
                console.log(err)
                this.$toast.error(err.message ?? 'Falha ao comunicar com o back-end!');
            } finally {
                this.table.isLoading = false;
            }
        },
        handleGridItemsActions: function (action, item) {
            switch (action) {
                case 'update':
                    this.selectedId = item.id;
                    this.modals.criarEditarLancamento.isOpened = true;
                    break;
                case 'see-service':
                    this.$router.push({
                        path: `/controle-servicos/${item.id}`,
                    })
                    break;
                default:
                    this.$toast.error('Opção de ação inválida!');
            };
        },
        handleModal: async function (operation) {
            if (operation === 'close') {
                this.modals.criarEditarLancamento.isOpened = false;
                this.selectedId = null;
                await this.loadData();
            };
            if (operation === 'open') {
                this.selectedId = null;
                this.modals.criarEditarLancamento.isOpened = true;
            }
        }
    },
    components: {
        Grid,
        ServicosControleHorasForm        
    },
    setup() {
        return {
            columns
        }
    }
})

</script>
<style></style>